import { cartState, userState } from "@/@stores/stores";
import Cookie from "js-cookie";
import { cookies } from "next/headers";
import toast from "react-hot-toast";
import { useRecoilValue } from "recoil";

export default function useData() {
  const user = useRecoilValue(userState);
  const cart = useRecoilValue(cartState);

  async function getData({
    uuid,
    offset,
    filters,
    novidades,
    exclusivos,
    kosher,
    transforma,
    sazonal,
  }: {
    uuid?: string;
    offset: number;
    filters: any;
    novidades?: boolean;
    exclusivos?: boolean;
    kosher?: boolean;
    transforma?: boolean;
    sazonal?: boolean;
  }) {
    console.log("ENTROU: ");
    const secret = Cookie.get("secret");

    const res = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/api/products?offset=${offset}&limit=28&category=${uuid}&filters=${filters}&novidades=${novidades}&kosher=${kosher}&transforma=${transforma}&exclusivos=${exclusivos}&sazonal=${sazonal}`,
      {
        next: {
          revalidate: 3600,
        },

        headers: {
          "X-Api-Key": `${secret}`, // secret,
          "Content-Type": "application/json",
        },
      }
    );
    // if (!res.ok) {
    //   throw new Error("Failed to fetch data");
    // }

    return res.json();
  }

  async function createOrder({ cart, bloc }: { cart: any; bloc: any }) {
    const token = Cookie.get("auth");
    const origin =
      process.env.NEXT_PUBLIC_SHOWROOM === "true" ? "showroom" : "web";

    const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/api/orders`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        order: {
          origin,
          raw: {
            cart,
            bloc,
          },
        },
      }),
    });

    // gtag event
    (window as any).gtag("event", "begin_checkout");

    if (res.ok) {
      toast.success("Pedido criado com sucesso!");

      // gtag event
      (window as any).gtag("event", "purchase", {
        items: cart.map((item: any) => item.product),
      });

      return { success: true };
    } else {
      const { error } = await res.json();
      toast.error(error);
      return { success: false, error };
    }
  }

  function formatCartToBloc(uuid: string, date: string, address: any) {
    if (!user || !cart) {
      return;
    }

    const tel = user?.telefone?.replace(/\D/g, "");
    const ddd = tel?.substring(0, 2);
    const telefone = tel?.substring(2, tel?.length);

    let bloc = {
      pedidoweb_id: uuid,
      data_orcamento: date,
      data_entrega: date,
      data_coleta: date,
      data_evento_ini: date,
      data_evento_fim: date,
      local_evento: "",
      nome: user.name,
      cpf_cnpj: user.cpf_cnpj,
      rg_ie: "",
      cep: address.cep,
      tipo_logradouro: "",
      logradouro: address.logradouro,
      logradouro_numero: address.numero,
      logradouro_complemento: address.complemento,
      bairro: address.bairro,
      cidade: address.cidade,
      uf: address.estado,
      telefone_ddd: ddd,
      telefone_numero: telefone,
      email: user.email,
      itens: [] as any[],
    };

    cart.forEach((item: any) => {
      bloc.itens.push({
        itemkit_id: item.product.bloc_id,
        qtde: item.quantity,
      });
    });

    return bloc;
  }

  // fetch address from viacep api
  async function getAddress(cep: string) {
    const cepSemMascara = cep.replace(/\D/g, "");
    const res = await fetch(`https://viacep.com.br/ws/${cepSemMascara}/json`);
    if (!res.ok) {
      throw new Error("Failed to fetch data");
    }

    return res.json();
  }

  return {
    getData,
    createOrder,
    formatCartToBloc,
    getAddress,
  };
}
