import Image from "next/image";
import { HTMLAttributes, useMemo } from "react";

import styles from "./Button.module.scss";
import Loader from "./loader-alt.svg";

interface Props extends HTMLAttributes<HTMLButtonElement> {
  title: any;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  size?: "small" | "medium" | "large";
  loading?: boolean;
  disabled?: boolean;
}

export default function Button(props: Props) {
  const {
    title,
    onClick,
    size = "medium",
    loading,
    disabled = false,
    ...rest
  } = props;

  const memoStyles = useMemo(() => {
    const style = {
      fontSize:
        size === "small" ? "0.75rem" : size === "large" ? "1.25rem" : "0.9rem",
      padding:
        size === "small"
          ? "0.5rem 0.8rem"
          : size === "large"
          ? "1rem 2rem"
          : "0.75rem 1.5rem",
      backgroundColor: size === "small" ? "transparent" : "var(--primary)",
      color: size === "small" ? "var(--primary)" : "#fff",
      height: size === "small" ? "2rem" : "auto",
    };
    return style;
  }, [size]);

  return (
    <button
      className={styles.button}
      onClick={onClick}
      style={{ ...memoStyles }}
      disabled={disabled}
      {...rest}
    >
      {loading ? (
        <>
          {title}
          <Image
            src={Loader}
            width={16}
            height={16}
            alt="Loading"
            className={styles.spinner}
            style={{ marginLeft: "0.5rem" }}
          />
        </>
      ) : (
        title
      )}
    </button>
  );
}
