"use client";

import Image from "next/image";
import Link from "next/link";
import { useEffect, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";

import { cartState } from "@/@stores/stores";
import { Category, Product as ProductType } from "@/@types";
import useCart from "@/hooks/useCart";
import useWishlist from "@/hooks/useWishlist";

import Button from "../Button";
import Quantity from "../Quantity";
import styles from "./ProductDetail.module.scss";
import { IoCopyOutline, IoExpand, IoShareSocialOutline } from "react-icons/io5";

import { BiShoppingBag as ShoppingBag } from "react-icons/bi";
import { IoMdHeart, IoMdHeartEmpty } from "react-icons/io";
import { defineImageUrl, formatCurrency } from "@/utils";
import Select from "../Select";
import Grid from "../Grid";

import { breakDimensions } from "@/utils";

interface Props {
  product: any;
  showLinkToProduct?: boolean;
  onlyMainProduct?: boolean;
}

export default function ProductDetail(props: Props) {
  const { product, showLinkToProduct = false, onlyMainProduct = false } = props;
  const { handleAddToCartWithQuantity } = useCart();
  const { handleAddWishlist, checkIfIsInWishlist } = useWishlist();
  const cart = useRecoilValue(cartState);

  const [quantity, setQuantity] = useState(1);
  const [productOverride, setProductOverride] = useState<ProductType | null>(
    null
  );

  const [showCopyMessage, setShowCopyMessage] = useState(false);

  const [currentImage, setCurrentImage] = useState();

  const currentImageUrl = useMemo(() => {
    return defineImageUrl(currentImage || product?.image?.url);
  }, [currentImage]);

  const [loading, setLoading] = useState(false);

  const handleAddToCart = () => {
    setLoading(true);
    if (quantity === 0) return;

    setTimeout(() => {
      handleAddToCartWithQuantity(currentProduct, quantity);
      setQuantity(1);
      setLoading(false);
    }, 200);
  };

  useEffect(() => {
    setProductOverride(null);
    // setCurrentImage(product?.image?.url);
  }, [product]);

  // modify product from props
  const currentProduct = useMemo(() => {
    if (productOverride) return productOverride;

    return product;
  }, [product, productOverride]);

  useEffect(() => {
    setCurrentImage(currentProduct?.image?.url);
  }, [currentProduct]);

  function extractDimensionsFromBrackets(dimension: string) {
    // the string should be "Something [TAM G]" should return "TAM G"
    const match = dimension?.match(/\[.*?\]/g);
    return match ? match[0].replace(/[\[\]]/g, "") : "";
  }

  const defineOptions = useMemo(() => {
    let options = product?.related_products?.map((related: any) => ({
      label: [
        related.sku,
        related?.raw?.DIMENSAO,
        extractDimensionsFromBrackets(related.raw?.DESCRICAOCOMPLEMENTAR),
      ]
        .filter((item) => item !== "")
        .join(" - "),
      value: related.uuid,
    }));

    // add product itself as the first option
    options?.unshift({
      label: [
        product.sku,
        product?.raw?.DIMENSAO,
        extractDimensionsFromBrackets(product.raw?.DESCRICAOCOMPLEMENTAR),
      ]
        .filter((item) => item !== "")
        .join(" - "),
      value: product?.uuid,
    });

    return options;
  }, [product]);

  function alreadyOnCart(product: ProductType) {
    const item = cart.find((item: any) => item.product?.uuid === product?.uuid);

    if (item) {
      return (
        <div className={styles.ProductDetail__alreadyOnCart}>
          <ShoppingBag size={16} color="var(--text-secondary)" />
          <span>Adicionados {item.quantity} deste item no pedido</span>
        </div>
      );
    }
  }
  const shareUrl = useMemo(() => {
    return `https://api.whatsapp.com/send?text=Oi! Veja este produto na D.Filipa: ${process.env.NEXT_PUBLIC_APP_URL}/produto/a/${currentProduct?.uuid}`;
  }, [currentProduct]);

  async function loadProductFromApi(uuid: string) {
    // fetch product from API

    const res = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/api/products/${uuid}`,
      {
        next: {
          revalidate: 3600,
        },
      }
    );

    if (!res.ok) {
      throw new Error("Failed to fetch data");
    }

    return res.json();
  }

  function handleCopy() {
    setShowCopyMessage(true);

    setTimeout(() => {
      setShowCopyMessage(false);
    }, 1000);
  }

  const sortedVinculatedProducts = useMemo(() => {
    return currentProduct?.vinculated_products?.sort((a: any, b: any) => {
      return a.sku.localeCompare(b.sku);
    });
  }, [currentProduct?.vinculated_products]);

  return (
    <>
      <div className={styles.ProductDetail} key={product?.uuid}>
        <Grid paddingBottom="0rem">
          <div className={styles.ProductDetail__columns}>
            <div className={styles.ProductDetail__content}>
              {product?.raw?.DESTAQUESITE === "1" ||
              product?.raw?.EXCLUSIVO === "1" ? (
                <div className={styles.ProductDetail__badges}>
                  {product?.raw?.DESTAQUESITE === "1" && <span>Novo</span>}
                  {product?.raw?.EXCLUSIVO === "1" && <span>Exclusivo</span>}
                </div>
              ) : null}

              <div className={styles.ProductDetail__categories}>
                {product?.categories?.map((category: Category) => (
                  <Link
                    prefetch={false}
                    href={`/produtos/${category.slug}`}
                    key={`cat-${category.id}`}
                  >
                    {category.name}
                  </Link>
                ))}
              </div>

              <div className={styles.ProductDetail__title}>
                {currentProduct?.name}
              </div>
              <div className={styles.ProductDetail__sku}>
                {currentProduct?.sku}
              </div>

              {process.env.NEXT_PUBLIC_SHOWROOM === "true" && (
                <div className={styles.ProductDetail__price}>
                  {
                    // format to currency R$
                    formatCurrency(currentProduct?.price_rent || 0)
                  }
                </div>
              )}

              <div
                className={styles.ProductDetail__description}
                dangerouslySetInnerHTML={{
                  __html: breakDimensions(currentProduct?.raw?.DIMENSAO) || "",
                }}
              />

              {/* <div
                className={styles.ProductDetail__description}
                dangerouslySetInnerHTML={{
                  __html: currentProduct?.raw?.DIMENSAO || "",
                }}
              /> */}

              <div className={styles.ProductDetail__buttons}>
                {product?.related_products?.length > 0 && (
                  <Select
                    label="Opções"
                    onChange={(e: any) => {
                      const value = e.target.value;
                      const related = product?.related_products?.find(
                        (related: any) => related?.uuid === value
                      );
                      setProductOverride(related);
                    }}
                    options={defineOptions}
                  />
                )}
                <Quantity
                  label="Qtd."
                  quantity={quantity}
                  setQuantity={setQuantity}
                />
              </div>
              <div className={styles.ProductDetail__buttons}>
                <Button
                  title="Adicionar"
                  onClick={handleAddToCart}
                  loading={loading}
                  disabled={loading}
                />
                {alreadyOnCart(currentProduct)}
              </div>

              <div className={styles.ProductDetail__secondary_actions}>
                <div
                  onClick={() => handleAddWishlist(product)}
                  className={styles.ProductDetail__wishlist}
                >
                  {checkIfIsInWishlist(product) ? (
                    <>
                      <IoMdHeart size={18} color="var(--primary)" />
                    </>
                  ) : (
                    <>
                      <IoMdHeartEmpty size={18} color="var(--primary)" />{" "}
                    </>
                  )}
                </div>

                {showLinkToProduct && (
                  <div className={styles.ProductDetail__link}>
                    <Link
                      prefetch={false}
                      href={`/produto/a/${currentProduct?.uuid}`}
                      target="_blank"
                    >
                      <IoExpand size={18} />
                    </Link>
                  </div>
                )}

                <div className={styles.ProductDetail__link}>
                  <Link
                    prefetch={false}
                    href="#"
                    target="_blank"
                    onClick={(e) => {
                      e.preventDefault();
                      navigator.clipboard.writeText(
                        `${process.env.NEXT_PUBLIC_APP_URL}/produto/a/${currentProduct?.uuid}`
                      );
                      handleCopy();
                    }}
                  >
                    <IoCopyOutline size={18} />
                  </Link>
                </div>

                <div className={styles.ProductDetail__link}>
                  <Link prefetch={false} href={shareUrl} target="_blank">
                    <IoShareSocialOutline size={18} />
                  </Link>
                </div>
              </div>
              {showCopyMessage && (
                <div className={styles.ProductDetail__copyMessage}>
                  Link copiado para a área de transferência.
                </div>
              )}
            </div>

            <div className={styles.ProductDetail__images}>
              <div className={styles.ProductDetail__image}>
                <Image
                  key={currentImageUrl}
                  src={currentImageUrl}
                  alt={currentProduct?.name || "D.Filipa"}
                  fill
                  style={{
                    objectFit: "contain",
                  }}
                  priority
                />
              </div>
              {currentProduct?.product_images?.length > 0 && (
                <div className={styles.ProductDetail__thumbnails}>
                  <Image
                    key={currentProduct?.image?.url}
                    src={defineImageUrl(currentProduct?.image?.url)}
                    alt={currentProduct?.name || "D.Filipa"}
                    width={50}
                    height={50}
                    style={{
                      border: `1px solid ${
                        currentImage === currentProduct?.image?.url
                          ? "var(--border)"
                          : "transparent"
                      }`,
                    }}
                    priority
                    onClick={() => setCurrentImage(currentProduct?.image?.url)}
                  />
                  {currentProduct?.product_images?.map((image: any) => (
                    <Image
                      key={image?.url}
                      src={defineImageUrl(image?.image?.url)}
                      alt={currentProduct?.name || "D.Filipa"}
                      width={50}
                      height={50}
                      style={{
                        border: `1px solid ${
                          currentImage === image?.image?.url
                            ? "var(--border)"
                            : "transparent"
                        }`,
                      }}
                      priority
                      onClick={() => setCurrentImage(image?.image?.url)}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </Grid>
        {currentProduct?.vinculated_products?.length > 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "1rem",
              padding: "1rem",
              borderTop: "1px solid var(--border)",
            }}
          >
            {sortedVinculatedProducts.map((product: any) => (
              <div
                key={product.uuid}
                className={styles.ProductDetail__vinculated}
                style={{ cursor: "pointer" }}
                onClick={async () => {
                  const loadedProduct = await loadProductFromApi(product.uuid);

                  if (loadedProduct) {
                    setProductOverride(loadedProduct);
                    setCurrentImage(product.image?.url);
                  }
                }}
              >
                <Image
                  src={defineImageUrl(product.image?.url)}
                  alt={product.name}
                  width={80}
                  height={80}
                  priority
                  style={{
                    objectFit: "cover",
                  }}
                />
                <div
                  style={{
                    textAlign: "center",
                    fontSize: "0.7rem",
                    marginTop: "0.25rem",
                    color: "var(--text-secondary)",
                  }}
                >
                  {product.sku}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
}
